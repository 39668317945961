import Vue from "vue";
import Vuex from "vuex"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        isAuth: false,
        // url:"http://localhost:8000/",
        // url: "https://issyservices.uat.idooproject.com/",
        url: "https://issyrental-ws.idooprod.com/",
        // storage_url: 'http://issyrental-store-qa.s3.us-east-1.amazonaws.com/',
        storage_url: 'http://issyrental-store-prod.s3.us-east-1.amazonaws.com/',
        categoria: 0,
        fecha_recogida: null,
        fecha_regreso: null,
        fecha: null,
        sucursal: 0,
        error_fecha_recogida: false,
        error_fecha_regreso: false,
        error_sucursal_categoria: false,
        visible: true,
        edad: 0,
        tarifa: '',
        phone_number: "+(664) 524 2099"
    },
    mutations: {},
    actions: {}
})

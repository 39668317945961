import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */'../views/Home.vue')
        },
        {
            path: '/about',
            name: 'about',
            component: () => import(/* webpackChunkName: "about" */'../views/old/About.vue')
        },
        {
            path: '/success',
            name: 'success',
            component: () => import(/* webpackChunkName: "success" */'../views/Success.vue')
        },
        {
            path: '/reserva_success/:reserva_id',
            name: 'reserva_success',
            component: () => import(/* webpackChunkName: "success" */'../views/Success_Reserva.vue')
        },
        {
            path: '/rentar',
            name: 'Rentar',
            component: () => import(/* webpackChunkName: "about" */'../views/Formulario.vue')
        },
        {
            path: '/login',
            name: 'login',
            component: () => import(/* webpackChunkName: "servicios" */'../views/Login.vue')
        },
        {
            path: '/singup',
            name: 'singup',
            component: () => import(/* webpackChunkName: "servicios" */'../views/sigup.vue')
        },
        {
            path: '/fotos/:id',
            name: 'fotos',
            component: () => import(/* webpackChunkName: "fotos" */'../views/old/Fotos.vue')
        },
        {
            path: '/grid',
            name: 'grid',
            component: () => import(/* webpackChunkName: "fotos" */'../views/old/Grid.vue')
        },
        {
            path: '/cards',
            name: 'cards',
            component: () => import(/* webpackChunkName: "fotos" */'../views/old/Cards.vue')
        },
        {
            path: '/forms',
            name: 'forms',
            component: () => import(/* webpackChunkName: "fotos" */'../views/old/Form.vue')
        },
        {
            path: '/listado',
            name: 'Listado',
            component: () => import(/* webpackChunkName: "fotos" */'../views/Autos_List.vue')
        },
        {
            path: '/Terminos',
            name: 'Terminos y Condiciones',
            component: () => import(/* webpackChunkName: "fotos" */'../views/TerminosCondiciones.vue')
        },
        {
            path: '/contacto',
            name: 'contacto',
            component: () => import(/* webpackChunkName: "fotos" */'../views/contact.vue')
        }
    ]
})

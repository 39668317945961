<template>
  <div id="app">
    <SideBar></SideBar>
    <router-view></router-view>
    <div id="odometer" class="text-center">
      <p class="text-success">Kilometraje Libre</p>
      <span ref="odometer"></span>
    </div>
    <div v-if="showFloatingWindow" class="floating-window d-flex align-items-center bg-white-smoke"
         :class="{ 'transition-in': showFloatingWindow, 'transition-out': !showFloatingWindow }">
      <img :src="imagenURL" alt="Imagen de la ventana flotante" class="window-image">
      <p class="window-text text-dark">{{ texto }}</p>
    </div>
  </div>
</template>

<script>
import SideBar from '@/components/SideBar.vue'
import Odometer from "odometer";
import Swal from "sweetalert2";

export default {
  name: "app",
  components: {
    SideBar
  },
  data() {
    return {
      number: '1000000',
      showFloatingWindow: false,
      imagenURL: "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/van_transit.jpg", // Reemplaza con la URL de tu imagen
      texto: "Mensaje de la ventana flotante"
    }
  },
  mounted: function () {
    const odometer = new Odometer({
      el: this.$refs.odometer,
      value: this.number,
      format: 'd',
      theme: 'car',
      duration: 1,
      animation: 'count'
    });
    var self = this;
    this.odometroId = setInterval(function () {
      self.number++;
      odometer.update(self.number)
    }, 50);
    setInterval(() => {
        this.toggleFloatingWindow();
    }, 60000);
  },
  methods: {
    toggleFloatingWindow() {
      this.texto = "El cliente " + this.obtenerNombreAleatorio() + " " + this.obtenerApellidosAleatorios() + " " +
          " ha rentado un auto de la categoría " + this.obtenerCategoriaAutoAleatoria() + " por " + this.obtenerDias() + " días"
      this.imagenURL = this.obtenerImagenes()
      this.showFloatingWindow = true;
      setTimeout(() => {
        this.showFloatingWindow = false;
      }, 5000);
    },
    obtenerNombreAleatorio() {
      const nombres = [
        'Juan',
        'Pedro',
        'María',
        'Ana',
        'Carlos',
        'Laura',
        'José',
        'Miguel',
        'Luis',
        'Sofía',
        'Alejandro',
        'Daniel',
        'Isabella',
        'David',
        'Fernanda',
        'Javier',
        'Mariana',
        'Diego',
        'Valentina',
        'Rodrigo'
      ];
      return nombres[Math.floor(Math.random() * nombres.length)];
    },
    obtenerApellidosAleatorios() {
      const apellidos = [
        'G.',
        'R.',
        'H.',
        'L.',
        'P.',
        'S.'
      ];
      return apellidos[Math.floor(Math.random() * apellidos.length)];
    },
    obtenerDias() {
      const numeros = ['10', '5', '3', '14', '2', '1', '30', '8', '7', '9', '15'];
      return numeros[Math.floor(Math.random() * numeros.length)];
    },
    obtenerImagenes() {
      const categories = [
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/Utility-pickup-issyrental2.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/TOYOTA_SIENNA.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/TOYOTA_RAV4.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/TOYOTA_HIACE.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/NISSAN_MARCH.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/NISSAN_KICKS.png",
        "http://issyrental-store-prod.s3.us-east-1.amazonaws.com/categoria/KIA_SPORTAGE.png"
      ];
      return categories[Math.floor(Math.random() * categories.length)];
    },
    obtenerCategoriaAutoAleatoria() {
      const categorias = ['Economy', 'SUV', 'Compacto', 'Empresarial', 'Large Van AUT', 'Mini Van', 'Adventure'];
      return categorias[Math.floor(Math.random() * categorias.length)];
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
    clearInterval(this.odometroId);
  },
}
</script>
<style scoped>
#odometer {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  font-size: 28px;
}

#odometer p {
  font-size: 20px;
}

@media (max-width: 768px) {
  #odometer {
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 100;
    font-size: 18px;
  }

  #odometer p {
    font-size: 14px;
  }
}

@media print {
  #odometer {
    display: none;
  }
}

.floating-window {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 400px;
  background-color: #f1f1f1;
  padding: 5px;
  border-radius: 5px;
  box-shadow: #c5c3c3 5px 5px 5px 5px;
  z-index: 1000; /* Asegúrate de que esté por encima de otros elementos */
  scale: 0;
  transition: scale 2s ease-in-out;
}

.transition-in {
  scale: 1;
}

.transition-out {
  scale: 0;
}

.window-image {
  height: 100px;
  object-fit: cover;
}

.window-text {
  margin-left: 10px;
  font-size: 14px;
}
</style>
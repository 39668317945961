<template>
  <div>
    <header class="header" style="height: 60px;">
      <div class="container-fluid">
        <nav class="navbar navbar-expand header-nav d-flex align-items-center banner" style="height: 60px;">
          <div class="navbar-header" style="height: 80px;">
            <a id="mobile_btn" href="javascript:void(0);">
							<span class="bar-icon">
								<span></span>
								<span></span>
								<span></span>
							</span>
            </a>
            <router-link :to="{name:'home'}" class="navbar-brand logo">
              <img src="@/assets/img/issyrentallogoweb.png" class="img-fluid d-print-none" style="height: 40px; width: 80px;"
                   alt="Logo">
            </router-link>
            <router-link :to="{name:'home'}" class="navbar-brand logo-small">
              <img src="@/assets/img/issyrentallogoweb.png" class="img-fluid d-print-none" style="height: 40px; width: 80px;"
                   alt="Logo">
            </router-link>
          </div>
          <div class="main-menu-wrapper mi-menu">
            <div class="menu-header">
              <router-link :to="{name:'home'}" class="menu-logo">
                <img src="@/assets/img/issyrentallogoweb.png" class="img-fluid" alt="Logo">
              </router-link>
              <a id="menu_close" class="menu-close" href="javascript:void(0);"> <i class="fas fa-times"></i></a>
            </div>
            <ul class="main-nav" style="height: 40px;">
              <li v-bind:class="$route.name==='home'?'active':''">
                <router-link style="font-size: 16px" class="nav-link" :to="{name:'home'}">Inicio</router-link>
              </li>
              <li v-bind:class="$route.name==='Listado'?'active':''">
                <router-link style="font-size: 16px" class="nav-link" :to="{name:'Listado'}">Rentar</router-link>
              </li>
              <li v-bind:class="$route.name==='Terminos y Condiciones'?'active':''">
                <router-link style="font-size: 16px" :to="{name:'Terminos y Condiciones'}" class="nav-link">Términos y
                  Condiciones
                </router-link>
              </li>
              <li v-bind:class="$route.name==='contacto'?'active':''">
                <router-link style="font-size: 16px" :to="{name:'contacto'}" class="nav-link">Contáctenos</router-link>
              </li>
            </ul>

          </div>
          <div class="redes-sociales d-print-none">
            <a href="https://www.facebook.com/profile.php?id=61550722958003&mibextid=LQQJ4d" class="icono"><img src="@/assets/img/icons/facebook.svg" alt="Facebook"></a>
            <a href="https://www.instagram.com/issy_rental" class="icono"><img src="@/assets/img/icons/instagram.png" alt="Facebook"></a>
          </div>
        </nav>
      </div>
    </header>
  </div>
</template>

<script>
export default {
  name: 'SideBar',

}
</script>
<style scoped>
@media print {
  ul {
    display: none;
  }
}

.mi-menu {
  height: 60px;
}

@media (max-width: 767px) {
  .mi-menu {
    height: 270px;
  }
}
.banner {
  position: relative;
}

.redes-sociales {
  position: absolute;
  bottom: 17px;
  right: 10px;
}

.redes-sociales .icono {
  display: inline-block;
  margin-left: 10px;
}

.redes-sociales .icono img {
  width: 25px;
  height: 25px;
}
</style>
